import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { AsyncMessageBundleManager, createAsyncMessageBundleManager, LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import { translations } from 'src/i18n/translations/translations-json';

import '@amzn/paragon-dynamic-view-renderer/lib/styles.css';
import '@amzn/katal-components/styles.css';
import '@amzn/paragon-ui-react/lib/styles.css';

import { KatSpinner } from '@amzn/katal-react';
import { installWidgetResizeWatcher } from '@amzn/paragon-core-data-context/lib/installWidgetResizeWatcher';
import { ParagonContextConsumerEl } from '@amzn/paragon-ui-react';

import AppInitWrapper from 'src/components/AppInitWrapper';
import { ProviderMocks } from './components/ProviderMocks';

const DemoWidget = lazy(() => import('src/widgets/DemoWidget').then((module) => ({default: module.DemoWidget})));
const IssueSummaryWidget = lazy(() => import('src/widgets/IssueSummaryWidget').then((module) => ({default: module.IssueSummaryWidget})));
const LandingPage = lazy(() => import('src/widgets/LandingPage').then((module) => ({default: module.LandingPage})));
const TransferWidget = lazy(() => import('src/widgets/TransferCase').then((module) => ({default: module.TransferWidget})));
const ReplyWidget = lazy(() => import('src/widgets/ReplyWidget').then((module) => ({default: module.ReplyWidget})));
const ReplyCaseWidget = lazy(() => import('./widgets/IssueSummaryWidget/components/widgets/ReplyCase/ReplyCaseWidget').then((module) => ({default: module.ReplyCaseWidget})));
const ActionPlanWidget = lazy(() => import('src/widgets/ActionPlanWidget').then((module) => ({default: module.ActionPlanWidget})));
const DemoGatingWidget = lazy(() => import('src/widgets/DemoGatingWidget').then((module) => ({default: module.DemoGatingWidget})));

import { DEFAULT_LOCALE } from 'src/constants';
import arbManifest from 'src/i18n/translations/arbManifest';

import './global.scss';
import { ServiceAPIContextProvider } from "src/service/service.context";
// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

installWidgetResizeWatcher();

const CreateMbm = (locale: string): AsyncMessageBundleManager => {
  const defaultLocale = DEFAULT_LOCALE

  const localizationContextProvider = () =>
    new LocalizationContextBuilder().withLocale(locale).withDefaultLocale(defaultLocale).build()

  return createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    resolveResourcePack: async () => await new Promise((resolve, reject) => {
      try {
        resolve(translations)
      } catch (e) {
        reject(e)
      }
    })
  })
}


const mbm = CreateMbm(DEFAULT_LOCALE)

ReactDOM.render(
  <React.StrictMode>
    <MbmProvider mbm={mbm}>
      <AppInitWrapper>
        <React.Suspense fallback={<KatSpinner size="large" />}>
          <ProviderMocks>
            <ParagonContextConsumerEl>
              <ServiceAPIContextProvider>
                <HashRouter>
                  <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/demo-widget" element={<DemoWidget />} />
                    <Route path="/demo-gating-widget" element={<DemoGatingWidget />} />
                    <Route path="/transfer-case" element={<TransferWidget />} />
                    <Route path="/issue-summary" element={<IssueSummaryWidget />} />
                    <Route path="/llm-reply-widget" element={<ReplyWidget />} />
                    <Route path="/reply-case" element={<ReplyCaseWidget />} />
                    <Route path="/action-plan" element={<ActionPlanWidget />} />
                  </Routes>
                </HashRouter>
              </ServiceAPIContextProvider>
            </ParagonContextConsumerEl>
          </ProviderMocks>
        </React.Suspense>
      </AppInitWrapper>
    </MbmProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
