import { issueSummaryContext } from "@amzn/paragon-context/lib/IssueSummaryContext";
import { TestConstants } from "./testContants";
import { GetQueueDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetQueueData";

export function installIssueSummaryContextProvider(el: HTMLElement) {
  const provider = issueSummaryContext.createProvider(el);

  provider.value = {
    caseDetails: {
        caseId: TestConstants.caseId,
        merchantId: TestConstants.merchantId,
        queue: 'US_BRI_Test_Queue',
        amazonGenerated: false,
        creationDate: Date.now(),
        owner: '',
        primaryEmail: '',
        severity: 5,
        status: 'Unassigned',
        subject: '',
        tenantId: 1,
        ccList: '',
        responseSlaMinutes: 0,
        responseSlaStartDate: 0,
        typeName: '',
        categoryName: '',
        canEscalate: false,
        reasonCategoryList: [],
        crmType: 'CASE'
    }
  };
  provider.functions.getQueueData = async () => {
    return {
      transferQueueList: [],
      suggestedTransferQueueList: [],
      lockCaseTransferQueueList: [],
      isQueueEnabledForCaseTags: false,
      isQueueEnabledForTransferReasons: false,
      isShowAdvancedTransferCase: false,
      caseTagOptions: {
        problemTypeCaseTagList: [],
        sellerResidenceTagList: [],
        languageCaseTagList: []
      },
      caseTags: {
        caseSeverity: "5",
        language: "EN",
        marketplace: "1",
        network: "",
        problemType: "",
        sellerResidence: ""
      }
    } as GetQueueDataResponse
  }
}
