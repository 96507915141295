export interface SCEDataRequest {
    schemaCaseElementPrefix: SchemaCaseElementsPrefix;
    schemaPropertyAttribute: SchemaDataProperties;
    schemaPropertyValue: SchemaDataProperties;
}

export enum SchemaCaseElementsPrefix {
    LLM_SCHEMA_PREFIX = "LLMPredictions.Results",
}

export enum SchemaDataProperties {
    LLM_SCHEMA_USE_CASE = "LLMUseCase",
    LLM_SCHEMA_ISSUE_SUMMARY = "LLM_ISSUE_SUMMARY",
}

export interface SchemaCaseElementsDataResponse {
    schemaElementsData: Record<string, SchemaElementData>;
}

export interface SchemaElementData {
    createdAt: Date;
    schema: Schema;
    schemaData: string;
    schemaElementId: string;
    schemaElementType: string;
    schemaPrefix: SchemaCaseElementsPrefix;
}

export interface LLMSchemaData {
    RequestId?: string;
    RequestText?: string;
    LLMUseCase: SchemaDataProperties;
    LLMModelId?: string;
    PredictedText: string;
    Metadata: LLMMetaData;
    PredictedTextTranslations?: Record<string, string>;
}

export interface LLMMetaData {
    AttackDetected?: boolean;
    AttackReasoning?: string;
    ActionsTaken?: LLMActions[];
    Identifiers?: LLMIdentifiers;
    AttachmentResult?: LLMAttachmentResult;
    Tickets?: LLMTicket[];
    Exception?: LLMException;
    DominantLanguage?: string;
}

export interface LLMActions {
    Action: string;
    Source: LLMSource[];
}

export interface LLMSource {
    Type: string,
    Id: string
}

export interface LLMIdentifiers {
    ASIN?: string[],
    ShipmentId?: string[],
    FNSKU?: string[],
    ReimbursementId?: string[],
    OrderId?: string[]
}

export interface LLMError {
    ErrorCode: string,
    Message: string
}

export interface LLMAttachmentResult {
    Attachments: LLMAttachment[];
}

export interface LLMAttachment {
    Id: string,
    Name: string,
    Summary: string,
    Source: LLMSource,
    AttachmentError?: LLMError
}

export interface WrongCTI {
    PotentiallyWrong: boolean,
    Reason: string
}

export interface LLMTicket {
    Id: string,
    Title?: string,
    CreationDate?: string,
    LastModifiedDate?: string,
    ClosureCode?: string,
    WrongCTI?: WrongCTI,
    Status?: string,
    Summary?: string,
    SummaryError?: LLMError,
    Url: string,
}

export interface LLMException {
    Type: string | null,
    Message: string | null
}

export const IdentifierMap = {
    "ASIN": "ASINs",
    "FNSKU": "FNSKUs",
    "OrderId": "Order IDs",
    "ShipmentId": "Shipment IDs",
    "ReimbursementId": "Reimbursement IDs"
}

export type SchemaCaseElementPage = {
    schemaCaseElements:  SchemaCaseElement[],
    nextPageToken?: string,
    schemaCaseElementPrefix: string,
}

export type SchemaCaseElement = {
    data: string,
    elementId: string,
    elementType: string,
    schema: Schema,
}

export type Schema = {
    name: string,
    version: string,
}

