import { TestConstants } from "./testContants";

export const TestData = {
  emptyTicketSummary: {
    Id: "P0",
    Title: "Yet another ticket name",
    CreationDate: "2025-02-17 8:08:28 AM (UTC-08:00)",
    WrongCTI: {
        PotentiallyWrong: false
    },
    LastModifiedDate: "2025-02-21 12:55:49 AM (UTC-08:00)",
    ClosureCode: "Successful",
    Status: "Resolved",
    Summary: "",
    SummaryError: {
      ErrorCode: "LLMFailure",
      ErrorMessage: "No summary was found"
    },
      
    Url: "https://t.corp.amazon.com/P0"
  },
  ticketSummary: {
    Id: "P1",
    Title: "Appeal to check Brand Protection ASIN suppression for Seller T4 Actioned",
    CreationDate: "2025-02-17 8:08:28 AM (UTC-08:00)",
    WrongCTI: {
      PotentiallyWrong: true,
      Reason: "Change of expiration date invalid CTI"
    },
    LastModifiedDate: "2025-02-21 12:56:49 AM (UTC-08:00)",
    ClosureCode: "Successful",
    Status: "Resolved",
    Summary:  `The ticket related to brand registry and international trademark verification, with Paragon Case ID <redacted>,
    is currently assigned. The ticket has been transferred due to a technical glitch preventing access to the TMR website.
      Further investigation is requested to address this issue,`,
    Url: "https://t.corp.amazon.com/P1"
  },
  longTicketSummary: {
    Id: "P2",
    Title: "Troubleshooting Robot - Relationships Sticky Delete Appeal - Seller ID: <redacted> Child ASIN: B07ZLG39RM Parent ASIN: B0D2GCJ48C // T4 Actioned",
    CreationDate: "2025-02-16 8:08:28 AM (UTC-08:00)",
    WrongCTI: {
      PotentiallyWrong: true,
      Reason: "Change of expiration date invalid CTI"
    },
    LastModifiedDate: "2025-02-25 12:55:50 AM (UTC-08:00)",
    ClosureCode: "Unsuccessful",
    Status: "Resolved",
    Summary: `The ticket related to brand registry and international trademark verification, with Paragon Case ID <redacted>,
    is currently assigned. The ticket has been transferred due to a technical glitch preventing access to the TMR website.
      Further investigation is requested to address this issue,`,
    Url: "https://t.corp.amazon.com/P1",
  },
  newerTicketSummary: {
    Id: "P3",
    Title: "I AM NEW",
    CreationDate: "2025-02-16 8:08:28 AM (UTC-08:00)",
    WrongCTI: {
      PotentiallyWrong: false
    },
    LastModifiedDate: "2025-02-26 12:55:49 AM (UTC-08:00)",
    Status: "Open",
    Summary: "a summary can be short",
  },
  failedTicketSummary: {
    Id: "b7b915e6-4c46-49c2-a28d-9063775a2a74",
    Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74",
    SummaryError: {
      ErrorCode: "LLM_ERROR",
      Message: "Error generating summary"
    }
  },
  attachmentSummary: {
    Id: TestConstants.attachmentId,
    Name: TestConstants.attachmentName,
    Summary: `A less obnoxious Summary.atiigula inceptos class dictumst maximus mi ante sollicitudin luctus curae. Commodo viverra iaculis vivamus potenti duis.
    Facilisi amet fames convallis mollis; nec arcu? Class eu mauris, vitae porta habitasse maecenas. Nam nunc ante vitae viverra vulputate efficitur.`,
    Source: {
        Type: "Correspondence",
        Id: TestConstants.asin
    }
  },
  shorterAttachmentSummary: {
    Id: TestConstants.asin,
    Name: "cat.jpg",
    Summary: `TEST SUMMARY & 5999<br>
    What happens with<br/>
    Line breaks`,
    Source: {
      Type: "Correspondence",
      Id: TestConstants.asin
    }
  },
  attachmentSummaryWithError: {
    Id: TestConstants.asin,
    Name: "My empty file",
    Summary: "",
    AttachmentError: {
      ErrorCode: "UNSUPPORTED_CONTENT_TYPE",
      Message: "Error message",
    },
    Source: {
      Type: "Correspondence",
      Id: TestConstants.asin
    }
  },
  longAttachmentSummary: {
    Id: TestConstants.asin,
    Name: "359px-Cat_November_2010-1a-very-long-cat.jpg",
    Summary: `TEST SUMMARY & 5999<br>
    What happens with<br/>
    Line breaks`,
    Source: {
      Type: "Correspondence",
      Id: TestConstants.asin
    }
  },
  longTicketSummaries: [
    {
      Id: "P4",
      Title: "Adjustment Request – Single [315693532002] - [US] - [VAT refund of Amazon fees]",
      CreationDate: "2025-02-16 8:00:00 AM (UTC-08:00)",
      WrongCTI: {
        PotentiallyWrong: false
      },
      LastModifiedDate: "2025-02-27 12:00:00 AM (UTC-08:00)",
      Status: "Open",
      Summary: "a summary can be long",
      Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74"
    },
    {
      Id: "P5",
      Title: "Request to change expiration date attributes for B0081K38QE | T4 Actioned",
      CreationDate: "2025-02-15 8:00:00 AM (UTC-08:00)",
      WrongCTI: {
        PotentiallyWrong: false
      },
      LastModifiedDate: "2025-02-28 12:00:00 AM (UTC-08:00)",
      Status: "Assigned",
      Summary: "a summary can be short",
      Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74"
    },
    {
      Id: "P6",
      Title: "Match Only Application Issue 5461 - Case ID 17206268101 , Seller ID 38545250505-t4 actioned",
      CreationDate: "2025-02-15 8:00:00 AM (UTC-08:00)",
      WrongCTI: {
        PotentiallyWrong: false
      },
      LastModifiedDate: "2025-03-01 12:00:00 AM (UTC-08:00)",
      Status: "Closed",
      Summary: "a summary can be short",
      Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74"
    },
    {
      Id: "P7",
      Title: "Brand registry &ndash; US/ TM office &ndash; Paragon Case ID: 17231035211 &ndash; International TM verification -T4 ACTIONED",
      CreationDate: "2025-02-15 8:00:00 AM (UTC-08:00)",
      WrongCTI: {
        PotentiallyWrong: false
      },
      LastModifiedDate: "2025-03-02 12:00:00 AM (UTC-08:00)",
      Status: "Researching",
      Summary: "a summary can be short",
      Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74"
    },
    {
      Id: "P8",
      Title: "***LCI Escalation case, case ID 16070756521***Troubleshooting Robot - Relationships Sticky Delete Appeal - Seller ID: 22326987215 : B0D2GCJ48C Child ASIN: B07ZPJHZ65 Parent ASIN: B0D2GCJ48C T4 Actioned",
      CreationDate: "2025-02-15 8:00:00 AM (UTC-08:00)",
      WrongCTI: {
        PotentiallyWrong: false
      },
      LastModifiedDate: "2025-03-03 12:00:00 AM (UTC-08:00)",
      Status: "Pending",
      Summary: "a summary can be long",
      Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74"
    },
    {
      Id: "b7b915e6-4c46-49c2-a28d-9063775a2a74",
      Url: "https://t.corp.amazon.com/b7b915e6-4c46-49c2-a28d-9063775a2a74",
      SummaryError: {
        ErrorCode: "LLM_ERROR",
        Message: "Error generating summary"
      }
    }
  ]
}
